import { render, staticRenderFns } from "./ChatBotIndex.vue?vue&type=template&id=46d20cfc&scoped=true&"
import script from "./ChatBotIndex.vue?vue&type=script&lang=ts&"
export * from "./ChatBotIndex.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d20cfc",
  null
  
)

export default component.exports